import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Base from "./components/layouts/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Home from "./pages/Home";
import "./App.scss";
import Solution from "./pages/Solution";
import Projects from "./pages/Projects";
import Reviews from "./pages/Reviews";
import ContactUs from "./pages/ContactUs";
import Blog from "./pages/Blog";

function App() {
  return (
    <>
      <Router>
        <Base>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/Solutions">
              <Solution />
            </Route>
            <Route exact path="/Projects">
              <Projects />
            </Route>
            <Route exact path="/Reviews">
              <Reviews />
            </Route>
            <Route exact path="/ContactUs">
              <ContactUs />
            </Route>
            <Route exact path="/Blog">
              <Blog />
            </Route>
          </Switch>
        </Base>
      </Router>
    </>
  );
}

export default App;
