import React, { useEffect } from "react";

import HomePage from '../components/Reviews/HomePage'

export default function Reviews() {
  useEffect(() => {
    document.title="Reviews - Axilaan"
  }, []);
  return (
    <>
       <HomePage/>
    </>
  )
}
