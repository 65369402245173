import React, { useEffect } from "react";

import Hero from '../components/projects/Hero'
import ProjectDetails from '../components/projects/ProjectDetails'
import ContactUs from '../components/solutions/ContactUs'

export default function Projects() {
  useEffect(() => {
    document.title="Projects - Axilaan"
  }, []);
  return (
    <>
        <Hero/>
        {/* <ProjectDetails/> */}
        <ContactUs/>
    </>
  )
}
