/** @format */

import React from "react";

export default function Hero() {
  return (
    <div className='container '>
      <div className='row'>
        <div className='col-md-6 hero'>
          <div>
            <h1>
              Web 3.0 decentralized Customer support service provider since the
              Internet
            </h1>
            <p>
              Foucs on resolving community issues as quickly as possible. We’ve been bringing custom and
          platform-based solutions to large and mid-sized companies in fin-tech, health care, education, AI and other decentralized applications.
            </p>
            <div className='d-flex align-items-center'>
              <hr style={{ width: "100px", color: "#FC00FF" }} />
              <a href='/'>Explore Now</a>
           <a href="#fea">   <button className='hero-btn'>
                <img src='assets/stroke 1.svg' alt='' />
              </button></a>
            </div>
          </div>
        </div>
        <div className='col-md-6 text-center hero-res-img'>
          <img src='assets/hero.png' className="w-100" alt='' />
        </div>
      </div>
    </div>
  );
}
