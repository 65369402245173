import React from "react";
import { MdStarRate } from "react-icons/md";
import { GoLocation } from "react-icons/go";
export default function Contact() {
  return (
    <div className="container section-padding">
      <div className="row">
        <h1 className="contactpage-head">
          Kickstart Your Digital <br /> Journey Today
        </h1>
        <div className="d-flex core-team-heading">
          <hr />
          <p>Contact Us</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 contactpage-left-col ">
          <p>
            Axilaan has very strong culture that takes care of its people really
            well. I would definitely recommend Axilaan to the startups as they
            are the perfect partner for any technical guidance.
          </p>
        </div>
        <div className="col-md-6 contactpage-left-col">
        
          <div className="row ">
            <div className="col-md-3 col-6">
              <img src="assets/reviews/ellipse 66.png" alt="" width={100}  className='res-contact-img' />
            </div>
            <div className="col-md-9 contact-details">
              <h2>Kathrine rows</h2>
              <span>CE0,Kozee Labs, Inc</span>
              <div className="review-rating">
                <MdStarRate /> &nbsp;4.8
              </div>
            </div>
          </div>
          <button>Request a Proposal</button>

        </div>
      </div>
    </div>
  );
}
