/** @format */

import React from "react";

export default function Hero() {
  return (
    <div className='container section-padding'>
      <div className='row'>
        <h1 className='solution-hero-text'>
          We Build Digital Products That <br className='line-break' /> Help Your
          Business Scale
        </h1>
        <div className='d-flex core-team-heading'>
          <hr />
          <p>Our Services</p>
        </div>
        <div className='col-md-4'>
          <div class='card'>
            <img
              src='assets/solutions/img.png'
              class='card-img-top'
              alt='abc'
            />
            <div class='card-body'>
              <h5 class='card-title'>Customer Service Representative </h5>
              <p class='card-text text-justify'>
                A Customer Service Representative works with clients who have
                complaints, orders, or require information about
                products/services purchased from the organization. They also
                provide solutions that fit those individualized situations and
                prioritize the customers' needs at each step of the process.
              </p>
              {/* <button className='hero-btn'>
                <img src='assets/stroke 1.svg' alt='' />
              </button> */}
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div class='card'>
            <img
              src='assets/solutions/digital-content.jpg'
              class='card-img-top'
              alt='abc'
            />
            <div class='card-body'>
              <h5 class='card-title'>Digital marketing</h5>
              <p class='card-text'>
                Digital marketing services are professional services that help
                market or advertise your business online, like through search,
                social media, and paid channels. They focus solely on digital
                channels versus traditional marketing channels like print or
                television.
              </p>
              {/* <button className='hero-btn'>
                <img src='assets/stroke 1.svg' alt='' />
              </button> */}
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div class='card'>
            <img
              src='assets/solutions/paid.jpg'
              class='card-img-top'
              alt='abc'
            />
            <div class='card-body'>
              <h5 class='card-title'>Paid advertising</h5>
              <p class='card-text'>
                Paid advertising is an online advertising model where
                advertisers bid to participate in real-time auctions in order to
                show their ads within slots on a specific platform or network.
              </p>
              {/* <button className='hero-btn'>
                  <img src='assets/stroke 1.svg' alt='' />
                </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
