/** @format */

import React from "react";

export default function Section1() {
  return (
    <div className='container section-padding'>
      <div className='row solution-sec1'>
        <h1>Make your business highly secure with our Customer services</h1>
        <p>
          We are a reputed Customer Support providers not just limited to
          blockchain but also encompas technical support for AI, Data Science,
          Health, Education and many other decentralized applications.
        </p>
        <button>Request a Proposal</button>
      </div>
      <div className='row'>
        <div className='col-md-3 col-6'>
          <div className='card sec1-card'>
            <div className='card-body '>
              <h1>30+</h1>
              <h3>Technology Experts</h3>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-6'>
          <div className='card sec1-card'>
            <div className='card-body'>
              <h1>30+</h1>
              <h3>Years of accumulative experience</h3>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-6'>
          <div className='card sec1-card'>
            <div className='card-body'>
              <h1>30+</h1>
              <h3>Blockchain Projects Delivered</h3>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-6'>
          <div className='card sec1-card'>
            <div className='card-body'>
              <h1>30+</h1>
              <h3>White label solutions developed in-house</h3>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 solution-sec1-para'>
          <p>
            Decentralized applications are bringing a radical change in the
            business world with its characteristics of community empowerment,
            immutability, transparency, and distributed ledger. We, with a clear
            understanding of its growing potential and experience of integrating
            customer support services solutions, are offering the best of both
            the worlds to startups and enterprises. From the initial to the
            processes before they go live on the unaltered system and helping
            Start-ups.
          </p>
          <p>
            We, at Axilaan, have proven to be one of the best decentralized apps
            customer service provider that serve you with a whole package - a
            team of Dapp customer service providers, Designers, and Marketers,
            who know this tech inside out and employ prerequisite tools and
            languages to develop a decentralized ecosystem for your brand. Our
            highly skilled representative have excelled their skills at working
            with multiple platforms, including diffreent customer support
            software tools, and thus gives you a wealth of options and solutions
            while planning to step into this world.
          </p>
        </div>
      </div>
    </div>
  );
}
