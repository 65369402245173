import React, { useEffect } from "react";

import AddAnovation from '../components/solutions/AddAnovation'
import ContactUs from '../components/solutions/ContactUs'
import Hero from '../components/solutions/Hero'
import LanguagesWeUse from '../components/solutions/LanguagesWeUse'
import PlatformWeUse from '../components/solutions/PlatformWeUse'
import Section1 from '../components/solutions/Section1'
import Faqs from '../components/utils/Faqs'
export default function Solution() {
  useEffect(() => {
    document.title="Solution - Axilaan"
  }, []);
  return (
    <>
    <Hero/>
    <Section1/>
    {/* <AddAnovation/> */}
    <PlatformWeUse/>
    {/* <LanguagesWeUse/> */}
    <Faqs/>
    <ContactUs/>
    </>
  )
}
