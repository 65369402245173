/** @format */

import React, { useEffect } from "react";
import BlogDetails from "../components/blogs/BlogDetails";
import Hero from "../components/blogs/Hero";

export default function Blog() {
  useEffect(() => {
    document.title="Blog - Axilaan"
  }, []);

  return (
    <>
      <Hero />
      <BlogDetails />
    </>
  );
}
