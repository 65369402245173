import React from "react";

export default function Trending() {
  return (
    <div className="container section-padding">
      <div className="row trending">
        <h1>Trending Opportunities</h1>
        <p>
          We promise you an inclusive work environment where you will fall in
          love with <br /> challenging as well as getting challenged
        </p>
        <div className="col-md-4">
          <p>Select department</p>
          <input
            type="text"
            name=""
            id=""
            className="form-control trending-input"
            placeholder="Select your role"
          />
        </div>
        <div className="col-md-6">
          <p>Select department</p>
          <div className="d-flex">
            <input
              type="text"
              name=""
              id=""
              className="form-control trending-input"
              placeholder="Experience"
            />
            <button className="trending-input-btn">Apply</button>
          </div>
        </div>
      </div>
    </div>
  );
}
