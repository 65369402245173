import React from "react";

export default function PlatformWeUse() {
  return (
    <div className="container">
      <div className="row platform-heading">
        <h1>
          The blockchain platforms <br /> and tech stack we trust to <br /> decentralize your
          business
        </h1>
        <p className="platform-p">
          Our blockchain app developers know what it takes to power up your
          business in a decentralized world. We work with different sets of
          tools and components to make your Dapps solutions successful.
        </p>
        <div className="d-flex core-team-heading">
          <hr />
          <p>blockchain platform</p>
        </div>
      </div>
      <div className="row">

        <div className="col-md-3 col-6 ">
<div className="platform-img d-flex justify-content-around align-items-center">
          <img src="assets/solutions/group 1000.png" className="w-75" alt="" />  
          </div>
        </div>
        <div className="col-md-3 col-6 ">
        <div className="platform-img d-flex justify-content-around align-items-center">
          <img src="assets/solutions/group 1001.png" className="w-75" alt="" />
          </div>
        </div>
        <div className="col-md-3 col-6 ">
        <div className="platform-img d-flex justify-content-around align-items-center">
          <img src="assets/solutions/group 1002.svg" className="w-75" alt="" />

          </div>
        </div>
        <div className="col-md-3 col-6 ">
        <div className="platform-img d-flex justify-content-around align-items-center">
          <img src="assets/solutions/group 1003.png" className="w-75" alt="" />
</div>
        </div>
        
      </div>
      <div className="row mt-2">
        <div className="col-md-3 col-6 ">
      <div className="platform-img d-flex justify-content-around align-items-center">
          <img src="assets/solutions/group 1004.png" className="w-75" alt="" />
          </div>
        </div>
        <div className="col-md-3 col-6 ">
        <div className="platform-img d-flex justify-content-around align-items-center">
          <img src="assets/solutions/group 1005.png" className="w-75" alt="" />
          </div>
        </div>
        <div className="col-md-3 col-6 ">
        <div className="platform-img d-flex justify-content-around align-items-center">
          <img src="assets/solutions/group 1006.png" className="w-75" alt="" />
          </div>
        </div>
        <div className="col-md-3 col-6 ">
        <div className="platform-img d-flex justify-content-around align-items-center">
          <img src="assets/solutions/group 1007.png" className="w-75" alt="" />
          </div>
        </div>
        
      </div>

    </div>
  );
}
