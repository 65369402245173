/** @format */

import React from "react";

export default function WhoWeAre() {
  return (
    <div className='container ' id="fea">
      <div className='row'>
        <h2 className='heading-text'>Who we are?</h2>
        <div className='col-md-1'></div>
        <div className='col-md-11'>
          <p className='sec3-para'>
            We are believers of change! A change driven by technology and
            innovation. We help businesses and individuals in adapting as well
            as adopting digital transformation. Our aim is to change people’s
            lives and improve businesses with our progressive and innovative
            technology solutions.
          </p>
        </div>
        <div className='col-md-1'></div>
        <div className='col-md-11'>
          <h2 className='heading-text'>What we do best...</h2>
        </div>
        <div className='col-md-2'></div>
        <div className='col-md-10'>
          <p className='sec3-para'>
            We are believers of change! A change driven by technology and
            innovation. We help businesses and individuals in adapting as well
            as adopting digital transformation. Our aim is to change people’s
            lives and improve businesses with our progressive and innovative
            technology solutions.
          </p>
        </div>
      </div>
      <h1 className='heading-text pt-4'>Work life at it’s best</h1>
      <div className='row mt-5 '>
        <div className='col-md-4  '>
          <div className='d-flex justify-content-around align-items-center m-2 boxes-para w-100'>
            <div className='img-box w-25 d-flex justify-content-around align-items-center'>
              <img src='assets/customer-support.png' className='w-75' alt='' />
            </div>
            <p className='w-75'>Customer support service</p>
          </div>
          <div className='d-flex justify-content-around align-items-center m-2 boxes-para w-100'>
            <div className='img-box w-25 d-flex justify-content-around align-items-center'>
              <img src='assets/team.png' className='w-75' alt='' />
            </div>
            <p className='w-75'>Community Building</p>
          </div>
          <div className='d-flex justify-content-center align-items-center m-2 boxes-para w-100'>
            <div className='img-box w-25 d-flex justify-content-around align-items-center'>
              <img src='assets/feedback.png' className='w-75' alt='' />
            </div>

            <p className='w-75'>Collect Users Feedback</p>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='d-flex justify-content-around align-items-center m-2 boxes-para w-100'>
            <div className='img-box w-25 d-flex justify-content-around align-items-center'>
              <img src='assets/social-media.png' className='w-75' alt='' />
            </div>

            <p className='w-75'>Social Media Campaign</p>
          </div>
          <div className='d-flex justify-content-around align-items-center m-2 boxes-para w-100'>
            <div className='img-box w-25 d-flex justify-content-around align-items-center'>
              <img src='assets/ad.png' className='w-75' alt='' />
            </div>

            <p className='w-75'>Paid Advertising</p>
          </div>
          <div className='d-flex justify-content-center align-items-center m-2 boxes-para w-100'>
            <div className='img-box w-25 d-flex justify-content-around align-items-center'>
              <img src='assets/search.png' className='w-75' alt='' />
            </div>

            <p className='w-75'>Product Testing</p>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='d-flex justify-content-around align-items-center m-2 boxes-para w-100'>
            <div className='img-box w-25 d-flex justify-content-around align-items-center'>
              <img src='assets/digital-marketing.png' className='w-75' alt='' />
            </div>

            <p className='w-75'>Digital Marketing</p>
          </div>
          <div className='d-flex justify-content-around align-items-center m-2 boxes-para w-100'>
            <div className='img-box w-25 d-flex justify-content-around align-items-center'>
              <img src='assets/profit.png' className='w-75' alt='' />
            </div>

            <p className='w-75'>Organic Business Growth</p>
          </div>
          <div className='d-flex justify-content-around align-items-center m-2 boxes-para w-100'>
            <div className='img-box w-25 d-flex justify-content-around align-items-center'>
              <img src='assets/conversation.png' className='w-75' alt='' />
            </div>

            <p className='w-75'>Border-Less Communication</p>
          </div>
        </div>
      </div>
      <div className='sec3-btn'>
      <a href="#contact">  <button className='btn'>Contact Now</button></a>
      </div>
    </div>
  );
}
