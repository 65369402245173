/** @format */

import React from "react";


export default function ContactUs() {
  return (
    <>
      {/* <img src="assets/ft-bg.png" alt="" className="ft-bg"/> */}
    
    </>
  );
}
