/** @format */

import React from "react";

export default function aboutCompany() {
  return (
    <div className='container section-padding'>
      <div className='row aboutcompany'>
        <div className='col-md-12'>
          <div className='page-heading'>
            <hr style={{ width: "100px" }} />
            <p className='page-heading-para'>home / company</p>
          </div>
        </div>
        <h1>Driven by ambition to find the best solution...</h1>
        <p>
          Post COVID19 <strong> Axilaan</strong>, has been innvotive service base categories
          ranging from Web 2.0 internet to Web 3.0 decentralized applications
          and much more.Founded in 2019,provides it base solutions.
        </p>
      </div>
      <div className='row'>
        <div className='col-md-12 text-center hero-res-img'>
          <img src={process.env.REACT_APP_S3_URL + "/video.png"} alt='' />
        </div>
      </div>
    </div>
  );
}
