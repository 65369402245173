/** @format */

import React from "react";
import { MdStarRate } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineTwitter } from "react-icons/ai";
import {
  FaDiscord,
  FaYoutube,
  FaInstagram,
  FaGithub,
  FaFacebook,
  FaTelegram,
  FaMediumM,
  FaReddit,
  FaLinkedin,
} from "react-icons/fa";

export default function Footer() {
  return (
    <>
      <footer>
        <div className='container-fluid contact-bg ' id='contact'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 pt-5'>
                <div className='ofc-details'>
                  <h1 className='office-country  m-auto px-5'>Pakistan(HQ)</h1>
                  <div className='row mt-5'>
                    <div className='col-md-2 text-end col-2 '>
                      <GoLocation className='location-icon' />
                    </div>
                    <div className='col-md-10 col-10'>
                      <p className='office-location'>
                        169-A, Civic Center, Phase 4, Bahria Town, <br />{" "}
                        Rewalpindi, Pakistan
                      </p>
                    </div>
                    <div className='col-md-2 text-end col-2'>
                      <BsTelephone className='location-icon' />
                    </div>
                    <div className='col-md-10 col-10'>
                      <a className='phone  ' href='tel:+923165854757'>
                        0316-5854757
                      </a>
                    </div>
                    <ul className='list-unstyled mt-5 mx-3 '>
                      <li>
                        <a target='_BLANK' href='https://twitter.com/Axilaan_'>
                          <AiOutlineTwitter />
                        </a>
                      </li>
                      <li>
                        <a target='_BLANK' href='https://www.linkedin.com/company/axilaan'>
                          <FaLinkedin />
                        </a>
                      </li>
                      {/* <li>
                  <a target='_BLANK' href='#'>
                    <FaDiscord />
                  </a>
                </li> */}
                      <li>
                        <a
                          target='_BLANK'
                          href='https://www.instagram.com/axilaan_/'>
                          <FaInstagram />
                        </a>
                      </li>
                      <li>
                        <a target='_BLANK' href='https://facebook.com/axilaan'>
                          <FaFacebook />
                        </a>
                      </li>{" "}
                    </ul>
                  </div>{" "}
                </div>
              </div>
              <div className='col-md-6 '>
                <div className='wrapper'>
                  <div className='title'>
                    <h1>contact us form</h1>
                  </div>
                  <div className='contact-form'>
                    <div className='input-fields'>
                      <input type='text' className='input' placeholder='Name' />
                      <input
                        type='text'
                        className='input'
                        placeholder='Email Address'
                      />
                      <input
                        type='text'
                        className='input'
                        placeholder='Phone'
                      />
                      <input
                        type='text'
                        className='input'
                        placeholder='Subject'
                      />
                    </div>
                    <div className='msg'>
                      <textarea placeholder='Message' defaultValue={""} />
                      <div className='btn-s'>send</div>
                    </div>
                  </div>
                </div>

                {/* <form className='contact-form-style'>
                <div className='row mt-5'>
                  <h1 className='contact-heading'>Contact Us</h1>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      class='form-control'
                      placeholder='First name'
                    />
                  </div>
                  <div className='col-md-6'>
                    <input
                      type='email'
                      class='form-control'
                      placeholder='Email'
                    />
                  </div>
                </div>
                <div className='input-group mb-3 mt-2'>
                  <div className='input-group-prepend'>
                    <button
                      className='btn btn-outline-secondary dropdown-toggle'
                      type='button'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'>
                      PK +92
                    </button>
                    <div className='dropdown-menu'>
                      <a className='dropdown-item' href='#'>
                        Action
                      </a>
                      <a className='dropdown-item' href='#'>
                        Another action
                      </a>
                      <a className='dropdown-item' href='#'>
                        Something else here
                      </a>
                      <div role='separator' className='dropdown-divider'></div>
                      <a className='dropdown-item' href='#'>
                        Separated link
                      </a>
                    </div>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    aria-label='Text input with dropdown button'
                    placeholder='Phone number'
                  />
                </div>
                <textarea
                  name=''
                  id='res-textarea'
                  cols='70'
                  className='form-control'
                  rows='7'
                  placeholder='Message....'></textarea>
                <button className='contactus-btn mt-3 m-auto'>Submit</button>
              </form> */}
              </div>
              <div className='pt-4 pb-3 '>
                <hr />
                <p className='font-wight text-light text-center'>
                  {" "}
                  &copy; 2023 Axilaan.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
