import React from 'react'

export default function Reviews() {
  return (
    <div className='container-fluid section-padding'>
        <div className="row sec">
            <div className="col-md-6 banner col-6">
                <h1>We seek <br />INNOVATORS</h1>
            </div>
            <div className="col-md-6 text-center col-6">
                <img src={process.env.REACT_APP_S3_URL+ "/Splines.png" }alt="" width={380}/>
            </div>
        </div>
    </div>
  )
}
