import React, { useEffect } from "react";

import Hero from '../components/elements/Hero'
import AboutCompany from '../components/elements/AboutCompany'
import WhoWeAre from '../components/elements/WhoWeAre'
import OurHistory from '../components/elements/OurHistory'
import OurAchievements from '../components/elements/OurAchievements'
import CoreTeam from '../components/elements/CoreTeam'
import Reviews from '../components/elements/Reviews'
import JoinTeam from '../components/elements/JoinTeam'
import Trending from '../components/elements/Trending'
import Carousal from '../components/elements/Carousal'
export default function Home() {
  useEffect(() => {
    document.title=" Axilaan"
  }, []);
  return (
    <>
    <Hero/>
    {/* <Carousal/> */}
    <AboutCompany/>
    <WhoWeAre/>
    <OurHistory/>
    <OurAchievements/>
    {/* <CoreTeam/> */}
    <Reviews/>
    <JoinTeam/>
    <Trending/>
    </>
  )
}
