import React from "react";

export default function JoinTeam() {
  return (
    <div className="container section-padding">
      <div className="row">
        <div className="col-md-6 join-team">
          <h1>Join the Axilaan team!</h1>
          <p>
            Innovate with the latest and greatest technologies & get to work on
            some of the coolest projects you can imagine and improve your skill set. Apply Now
          </p>
          <button className="btn btn-primary">Start Your Success</button>
        </div>
        <div className="col-md-6 hero-res-img">
          <img src={process.env.REACT_APP_S3_URL+ "/Rectangle+415.png"} alt="" />
        </div>
      </div>
   
    </div>
  );
}
