import React from "react";
import {BsArrowRight} from 'react-icons/bs'

export default function BlogDetails() {
  return (
    <div className="container section-padding">
      <div className="row">
        <div className="d-flex core-team-heading">
          <hr />
          <p>ReadMore Page</p>
        </div>
        <h1 className="blog-detail-heading">How Will Android 11 Features Impact Your Mobile Application?</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
      
        </div>
        <div className="col-md-12 blog-details-para">
          <p>
            Android 10 was a big shift for Google in the Operating System
            environment. We did not just finally get to work around the
            long-awaited Dark mode and saw several important changes happening
            on the app permission front, we also saw the end of an era.{" "}
          </p>
          <p>
            With Android 10, Google brought its tradition to name operating
            systems behind desserts to a halt.{" "}
          </p>
          <p>
            Android 10 was a testament to the growth and maturity graph that
            Google has taken. The same principles are being carried over by
            Android 11 developer preview now. While we are some months away from
            playing around the final build, the recent developer preview is a
            sufficient peek into where Google wants to take the operating
            system.{" "}
          </p>
          <p>
            With every new update, it is not just the Android app developers who
            get excited. The stakeholders on the other side of the coin – the
            app owners – also sit on the edge of their seats everytime around
            the mid of May when a new version is announced at the Google I/O
            events.{" "}
          </p>
        </div>
        <div className="col-md-12 blog-details-bottom">
            <img src="assets/blogs/rectangle 428.png" alt="" className="w-100" />
            <h1>What Would Migrating Apps to Android 11 Mean for Your Android App?</h1>
            <p>If you look at the Android 11 developer preview documentation, you will find that it is divided into three categories: Behaviour Changes, Privacy Features, and New Features & APIs. Under all the categories, there are pointers which carry an impact on the application’s performance. </p>
            <h3>Read more articles..</h3>
        </div>
        {/* <div className="row">
        <div className="col-md-4">
              <div className="card">
                  <img src="assets/blogs/img.png" alt="" />
                  
                  <div className="card-body blog-card-body">
                      <h1>AR/VR</h1>
                      <p>Blockchain solution for building and accounting game rating will allow to exclude the possibility.</p>
                      <a href="/">Read more <BsArrowRight/></a>
                  </div>
              </div>
          </div>
          <div className="col-md-4">
              <div className="card">
                  <img src="assets/blogs/img.png" alt="" />
                 
                  <div className="card-body blog-card-body">
                      <h1>AR/VR</h1>
                      <p>Blockchain solution for building and accounting game rating will allow to exclude the possibility.</p>
                      <a href="/">Read more <BsArrowRight/></a>
                  </div>
              </div>
          </div>
          <div className="col-md-4">
              <div className="card">
                  <img src="assets/blogs/img.png" alt="" />
              
                  <div className="card-body blog-card-body">
                      <h1>AR/VR</h1>
                      <p>Blockchain solution for building and accounting game rating will allow to exclude the possibility.</p>
                      <a href="/">Read more <BsArrowRight/></a>
                  </div>
              </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
