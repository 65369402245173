/** @format */

import React from "react";

export default function OurHistory() {
  return (
    <div className='container section-padding'>
      <div className='row'>
        <div className='history-heading'>
          <hr />
          <p>history 2017-2022</p>
        </div>
        <div className='col-md-6 history-body'>
          <h1>Our History...</h1>
          <button>2017</button>
          <p className='mt-4'>
            We touched a 100+ project milestone mark and strengthened our
            workforce by hiring 150+ employees from across locations, all of
            this in just a year. Ramped up all our processes to incorporate a
            smooth flow of work.
          </p>
        </div>
        <div className='col-md-6 hero-res-img'>
          {/* <img src={process.env.REACT_APP_S3_URL+ "/image.png"} alt="" /> */}
          <img src='assets/his.webp' className="w-75 d-block m-auto" />
        </div>
      </div>
    </div>
  );
}
