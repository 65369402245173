import React from "react";

export default function hero() {
  return (
    <div className="container section-padding">
      <div className="row project-heading">
        <h1>Crafted With Love.Built With Passion</h1>
        <p>
          Axilaan offers transformative technology and software solutions to
          startups, global brands & Fortune 500 companies. Some of our major
          clients include IKEA, Domino's, Adidas, KPMG, BCG & Pizza Hut.
        </p>
      </div>
      <div className="row">
        <div className="col-md-3 col-6">
          <div className="card project-card">
            <div className="card-body project-card-body">
              <h1>30+</h1>
              <h3>Blockchain Projects Delivered</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="card project-card">
            <div className="card-body project-card-body">
              <h1>25+</h1>
              <h3>CMS Managment System Created</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="card project-card">
            <div className="card-body project-card-body">
              <h1>21+</h1>
              <h3>AI Technology Supors</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="card project-card">
            <div className="card-body project-card-body">
              <h1>500+</h1>
              <h3>Game Development Delivered</h3>
            </div>
          </div>
        </div>
        {/* <div className="d-flex core-team-heading mt-5">
          <hr />
          <p>explore our projects</p>
          <button className="btn btn-secondary mx-2"><img src="assets/stroke 1.svg" alt="" /></button>
        </div> */}
      </div>
    </div>
  );
}
