/** @format */

import React from "react";
import Faq from "react-faq-component";

const data = {
  //   title: "FAQ (How it works)",
  rows: [
    {
      title: "How Self Support Works?",
      content: `Common forms of self-support include FAQs, white papers, user guides, and case studies.`,
    },
    {
      title: "How Anticipatory support Works?",
      content: `Our teams maintain a database of common customer support inquiries so they can anticipate issues frequently faced by customers, and address them even before they arise.`,
    },
    {
      title: "Responsive support",
      content: `Responsive support includes help offered to a customer experiencing an issue with a particular feature or tool after they reach out to your support team via email or call.
      `,
    },
    {
      title: "How Digital transformation can improve customer experience?",
      content: `Digital transformation is about going beyond merely digitizing and automating existing customer support processes. It is about creating platforms that allow customers to communicate, exchange data, and switch between different legacy systems seamlessly, thereby enhancing their experience.`,
    },
    {
      title: "How Customer Lifetime Value (CLV) is important in any business?",
      content: `The happier your customers, the more likely they are to maintain a long-term association with your brand. This, in turn, increases customer lifetime value (CLV), i.e. the amount of money a single client spends on your business during their association with you and lowers your operating costs to serve them.`,
    },
    {
      title: "What is customer success?",
      content: `Customer success is very much relationship-focused -- with every customer success manager responsible for a specific number of clients, ensuring they derive maximum value from the product or service.`,
    },
    {
      title: "Turning customers into loyal brand ambassadors",
      content: `Great customer success managers continuously work towards helping customers achieve their business goals. Consequently, they help build a community of committed and loyal brand ambassadors who in the long run are huge drivers of business growth - through positive word-of-mouth.`,
    },
    {
      title: "What are the most common customer support issues?",
      content: `Poor turnaround time can hugely tarnish a brand's reputation and credibility. Some simple tips for dealing with a barrage of queries and complaints from customers and reducing response time include: <br/>
      <ul>
        <li>Using email auto-responders
      </li>
        <li>Using email templates</li>
        <li>Categorizing emails using keywords depending on priority</li>
        <li>Setting time-based alerts</li>
      </ul>`,
    },
  ],
};

const styles = {
  bgColor: "#0E0E1DBF",
  titleTextColor: "#EBEEF2",
  rowTitleColor: "#EBEEF2",
  rowContentColor: "#A5ABB3",
  arrowColor: "#5B8DEF",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingLeft: "30px",
  rowContentPaddingRight: "30px",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  // tabFocus: true
  margin: "2px",
};

export default function Faqs() {
  return (
    <div className='container section-padding'>
      <div className='row justify-content-center'>
        <div className='col-md-12 w-75 '>
          <h1 className='text-center mb-5 text-light'>FAQS</h1>

          <div>
            <Faq data={data} styles={styles} config={config} />
          </div>
        </div>
      </div>
    </div>
  );
}
