import React from "react";

export default function OurAchievements() {
    const companyLogos = [
        {
          imgUrl: "assets/Company logo (1).svg",
        },
        {
          imgUrl: "assets/Company logo (2).svg",
        },
        {
          imgUrl: "assets/Company logo (3).svg",
        },
        {
          imgUrl: "assets/Company logo (4).svg",
        },
        {
          imgUrl: "assets/Company logo (3).svg",
        },
        {
          imgUrl: "assets/Company logo (1).svg",
        },
        {
          imgUrl: "assets/Company logo (2).svg",
        },
        {
          imgUrl: "assets/Company logo (7).png",
        },
        {
          imgUrl: "assets/Company logo (5).png",
        },
        {
          imgUrl: "assets/Company logo (6).png",
        },
        {
          imgUrl: "assets/logo11.svg",
        },
        {
          imgUrl: "assets/logo12.svg",
        },
        {
          imgUrl: "assets/logo13.svg",
        },
        {
          imgUrl: "assets/logo14.svg",
        },
        {
          imgUrl: "assets/logo15.svg",
        },
      ];
  return (
    <div className="container section-padding">
      <div className="row">
          <div className="d-flex achievement-heading">
          <hr  />
          <p>Awards</p>
            </div>
        <div className="col-md-12 achievement-body">
          <h1>Our Inspiration</h1>
          <p>
            With the utmost priority of adding value to the existing eco-system  using advance tools to meet our clients expectation and deliver one of the best possibal solution in the market.
          </p>
        </div>
        <div className="row">
        {companyLogos.map((v, i) => {
              return (
                <div className="col-md-4 col-6" key={i} data-aos="fade-up"
                data-aos-anchor-placement="top-center" data-aos-duration ='1500' >
                  <img src={v.imgUrl} alt="" className="image-overlay" />
                </div>
              );
            })}
        </div>
        <div className="row">
            <div className="col-md-4 col-6">
                <div className="card">
                    <div className="card-body text-center achievement-card-body" >
                        <h1>1000+</h1>
                        <p>Projects Delivered</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-6">
                <div className="card">
                    <div className="card-body text-center achievement-card-body" >
                        <h1>680+</h1>
                        <p>Projects Delivered</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body text-center achievement-card-body" >
                        <h1>1000+</h1>
                        <p>Projects Delivered</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}
