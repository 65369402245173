/** @format */

import React from "react";
import { BsArrowRight } from "react-icons/bs";
export default function Hero() {
  return (
    <div className='container section-padding'>
      <div className='row blog-row'>
        <h1>
          We Provide Customer Support Services for Digital Products to Accelrate
          their business and enhance user experiences based on their valuable
          feedback
        </h1>
        <div className='d-flex core-team-heading'>
          <hr />
          <p>Popular Articles</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          <div className='card'>
            <img src='assets/blogs/AdobeStock_479242725-scaled.jpeg' alt='' />
            <div className='card-body blog-card-body'>
              <h1>
                10 Ways To Build brand loyalty with your customers
              </h1>
              <p>
                Wondering how to retain your customers? Join us each week as we
                go through #1, all the way to #10 on how you can build brand
                loyalty with your customers.
              </p>
              {/* <a href='/'>
                Read more <BsArrowRight />
              </a> */}
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card'>
            <img src='assets/blogs/car_forest_small-scaled.jpg' alt='' />
          </div>
          <div className='card-body blog-card-body'>
            <h1>7 Ways To Build satisfaction and affirms Customer values  </h1>
            <p>
              By mapping every customer interaction, you can craft an experience
              that builds satisfaction and affirms their values every step of
              the way.
            </p>
            {/* <a href='/'>
              Read more <BsArrowRight />
            </a> */}
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card'>
            <img src='assets/blogs/128108027_l-scaled.jpg' alt='' />

            <div className='card-body blog-card-body'>
              <h1>
               Innovative Way of Improving Brand's and Customer
                Journey
              </h1>
              <p>
                By putting yourself in your customer's shoes, you can gain
                valuable insight on how to improve your brand's customer
                journey.
              </p>
              {/* <a href='/'>
                Read more <BsArrowRight />
              </a> */}
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4'>
          <div className='card'>
            <img src='assets/blogs/customer-appreciation.jpg' alt='' />
            <div className='card-body blog-card-body'>
              <h1>
         How to      meet challenges head-on, and delivering highly
                satisfying services
              </h1>
              <p>
                If you can get into the habit of keeping the customer at the
                center of everything you do, you’ll find your team is reacting
                less, meeting challenges head-on, and delivering highly
                satisfying services.{" "}
              </p>
              {/* <a href='/'>
                Read more <BsArrowRight />
              </a> */}
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card'>
            <img src='assets/blogs/AdobeStock_280821659-scaled.jpeg' alt='' />
          </div>
          <div className='card-body blog-card-body'>
            <h1>How  Customer Communication Can Helpful for any Business</h1>
            <p>
              Great brands work hard to make sure their customer communication
              is authentic, helpful, and empathetic.{" "}
            </p>
            {/* <a href='/'>
              Read more <BsArrowRight />
            </a> */}
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card'>
            <img src='assets/blogs/image_2022_03_08T17_27_01_302Z.png' alt='' />

            <div className='card-body blog-card-body'>
              <h1>How Customers Feedback is Important for long way Business journey</h1>
              <p>
                As in most relationships, hearing customers out and showing them
                that you’re making an effort to fix things goes a long way.{" "}
              </p>
              {/* <a href='/'>
                Read more <BsArrowRight />
              </a> */}
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4'>
          <div className='card'>
            <img src='assets/blogs/Reflecx-on-phone-scaled.jpg' alt='' />
            <div className='card-body blog-card-body'>
              <h1>
          How    to deliver the best
                Possible Relationships Across all your Channels in Different
                 Businesses
              </h1>
              <p>
                Invest in a best-in-class blockchain platform that gives you the
                speed, agility, and responsiveness you need to deliver the best
                possible relationships across all your channels.
              </p>{" "}
              {/* <a href='/'>
                Read more <BsArrowRight />
              </a> */}
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card'>
            <img src='assets/blogs/Blog-Image-scaled.jpg' alt='' />
          </div>
          <div className='card-body blog-card-body'>
            <h1>How to Predictable and   Retaining Loyal Customers</h1>
            <p>
              Retaining loyal customers means creating experiences that are
              predictable, repeatable and most importantly reliable.
            </p>
            {/* <a href='/'>
              Read more <BsArrowRight />
            </a> */}
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card'>
            <img src='assets/blogs/9-Blog-Image (1).jpg' alt='' />

            <div className='card-body blog-card-body'>
              <h1>
               How CSR  Strategy is Evolving for Businesses
              </h1>
              <p>
                The world is constantly evolving. Your CSR strategy, tactics,
                and customer journey need to be fluid, not final.
              </p>{" "}
              {/* <a href='/'>
                Read more <BsArrowRight />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
