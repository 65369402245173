import React, { useEffect } from "react";

import Contact from "../components/ContactUs/Contact";
import ExploreNow from "../components/ContactUs/ExploreNow";
import Proposal from "../components/ContactUs/Proposal";

export default function ContactUs() {
  useEffect(() => {
    document.title="ContactUs - Axilaan"
  }, []);
  return (
    <>
    <Contact/>
    {/* <Proposal/> */}
    {/* <ExploreNow/> */}
    </>
  );
}
