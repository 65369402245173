import React from "react";
import { MdStarRate } from "react-icons/md";

export default function HomePage() {
  return (
    <div className="container section-padding">
      <div className="row">
        <h1 className="review-sec-head">What client says about us?</h1>

        <div className="d-flex core-team-heading">
          <hr />
          <p>client reviews</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="row mt-5">
            <div className="col-md-4 ">
              <img src="assets/reviews/ellipse 66.png" alt=""/>
            </div>
            <div className="col-md-8  review-sec-body">
              <h1>Charles Cooper</h1>
              <span>CEO, Metakiller</span>
              <div className="review-rating">
                <MdStarRate /> &nbsp;4.8
              </div>
              <p>
                Here I focus web project without giving on a range of items and
                features that we use in elementor for our client web project
                without giving them a second thought.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row mt-5">
            <div className="col-md-4">
              <img src="assets/reviews/ellipse 66.png" alt="" />
            </div>
            <div className="col-md-8 review-sec-body">
              <h1>Charles Cooper</h1>
              <span>CEO, Metakiller</span>
              <div className="review-rating ">
                <MdStarRate /> &nbsp;4.8
              </div>
              <p>
                Here I focus web project without giving on a range of items and
                features that we use in elementor for our client web project
                without giving them a second thought.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
